import React, {useRef, useEffect} from 'react';
import {debounceQueryUpdate} from '../../hooks/useQueryParams';

function Checkbox(props) {
  const {element, className: classes, setFunction, title, name, value} = props;

  const onChange = (event) => {
    setFunction(event);
    debounceQueryUpdate(event.target.checked, name, element);
  }

    return (
        <div className={(classes ? " " + classes : '')}>
            <p className="control-title">{title}</p>
            <label className="toggle-control">
                <input type="checkbox" checked={value} onChange={onChange} value={value}/>
                <span className="control"></span>
            </label>
        </div>
    );
  }
  
  export default Checkbox;