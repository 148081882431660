import React from 'react';
import Range from './range';
import ColorPickerWrapper from './color-picker-wrapper';
import Select from 'react-select';
import Checkbox from './checkbox';
import {debounceQueryUpdate} from '../../hooks/useQueryParams';


function ProgressBar(props) {
  const {update, settings} = props;
  const {setProgressColor, setProgressPosition, setProgressBarHeight} = update;
  const {progressColor, height, progressPosition, progressBarHeight} = settings;
  
  const updateProgressColor = (value, valueToUpdate) => {
    setProgressColor((prevState) => ({...prevState,
                        [valueToUpdate]: value}));
  }

  const onSelect = (value, name) => {
    setProgressPosition(value);
    debounceQueryUpdate(value, name);
  }

    return (
        <div className="flex-auto p-10 pt-5 width-50% text-stone-500 col-span-2 md:col-span-1">
          <p className="section-title">Progress Bar</p>
            <div className="grid grid-cols-2 py-5 gap-10">
                
                <div className="col-span-1">
                    <p className="control-title">Position</p>
                    <Select value={{label:progressPosition.charAt(0).toUpperCase() + progressPosition.slice(1), value: progressPosition}} options={[{label:'Off', value: 'off'}, {label:'Top', value: 'top'},{label:'Behind', value: 'behind'}, {label:'Bottom', value: 'bottom'}]} onChange={(selection) => onSelect(selection.value, "progressPosition")} />
                </div>
                <Range name="progressBarHeight" label="Height" setFunction={setProgressBarHeight} value={progressBarHeight} max={height/2} className={ "col-span-1 " + (progressPosition === "behind" ? "disabled" : "")}/>
              </div>
                <div className="col-span-1 lg:col-span-1">
                  <p className="sub-section-title">Background</p>
                  <div className="grid grid-cols-10 gap-12">
                    <ColorPickerWrapper
                        color={ progressColor.firstColor }
                        onChange={ (color) => updateProgressColor(color, 'firstColor') }
                        label={progressColor.useGradient ? "First Color" : "Color"}
                        name="progressColor.firstColor"
                        element={ progressColor }
                        className='col-span-3'
                        />
                      <ColorPickerWrapper
                        color={ progressColor.secondColor }
                        onChange={ (color) => updateProgressColor(color, 'secondColor') }
                        label="Second Color"
                        className={'col-span-3' + (!progressColor.useGradient ? " disabled" : "")}
                        name="progressColor.secondColor"
                      element={ progressColor }
                      />
                      <Checkbox 
                    element={progressColor}
                    name="progressColor.useGradient"
                    setFunction={(event) => updateProgressColor(event.target.checked, 'useGradient')}
                    className="col-span-2"
                    title="Gradient"
                    value={progressColor.useGradient}
                  />
                  </div>
                  </div>
                  
                
                
                </div>
    );
  }
  
  export default ProgressBar;