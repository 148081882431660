import React from 'react';
import Range from './range';
import ColorPickerWrapper from './color-picker-wrapper';
import RadioGroup from './radio-group';

import Checkbox from './checkbox';

function Border(props) {
  const {update, settings} = props;
  const {setBorderColor, setBorderRadius, setBorderVisible, setBorderSize} = update;
  const {borderColor, borderRadius, borderVisible, height, borderSize} = settings;

  const setBorderRadiusPercent = (value) => {
    console.log(value);
    setBorderRadius(value);
  }

    return (
      <div className="flex-auto p-10 pt-5 width-50% text-stone-500 col-span-2 md:col-span-1">
          <p className="section-title">Border</p>
          <div className="grid grid-cols-6 gap-12 py-5">
            <Checkbox 
            setFunction={(event) => setBorderVisible(event.target.checked)}
                title="Border"
                name="borderVisible"
                value={borderVisible}
            />
            <div className={"col-span-2 " + (!borderVisible ? "disabled" : "")}>
              <ColorPickerWrapper 
              color={ borderColor }
              element={ borderColor }
              onChange={ setBorderColor }
              name="borderColor"
              label="Color"
              />
            </div>
            <div className={"col-span-3 " + (!borderVisible ? "disabled" : "")}>
              <Range name="borderSize" label="Size" setFunction={setBorderSize} value={borderSize < height / 2 ? borderSize : height / 2} max={height / 2} min={1}/>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            
            <Range name="borderRadius" className="col-span-1" label="Rounded Corners" setFunction={setBorderRadiusPercent} value={borderRadius} max={50}  />
          </div>
        </div>
        
    );
  }
  
  export default Border;