import React from 'react';
import Range from './range';
import ColorPickerWrapper from './color-picker-wrapper';
import Checkbox from './checkbox';

function General(props) {
  const {update, settings} = props;
  const {setBackgroundColor, setWidth, setHeight, setImgVisible} = update;
  const {backgroundColor, width, height, imgVisible} = settings;

  const updateBackgroundColor = (value, valueToUpdate) => {
    setBackgroundColor((prevState) => ({...prevState,
                        [valueToUpdate]: value}));
  }
  
    return (
        <div className="flex-auto p-10 pb-5 width-50% text-stone-500 col-span-2 md:col-span-1">
          <p className="section-title">General</p>
            <div className ="grid grid-cols-8 gap-12 py-5">
              <Range label="Height" name="height" setFunction={setHeight} value={height} max={150} min={20} className="col-span-8 sm:col-span-3 xs:col-span-8"/>
              <Range label="Width" name="width" setFunction={setWidth} value={width} max={1200}  min={250} className="col-span-8 sm:col-span-3 xs:col-span-8"/>
              <Checkbox 
                setFunction={(event) => setImgVisible(event.target.checked)}
                title="Image"
                name="imgVisible"
                value={imgVisible}
                className="col-span-2"
              />
            </div> 
            
            <div className="col-span-1">
              <p className="sub-section-title">Background</p>
              <div className="grid grid-cols-10 gap-12">
                
                <ColorPickerWrapper
                  color={ backgroundColor.firstColor }
                  onChange={ (color) => updateBackgroundColor(color, 'firstColor') }
                  label={backgroundColor.useGradient ? "First Color" : 'Color'}
                  className={"col-span-5 lg:col-span-3 md:col-span-4 sm:col-span-3 xs:col-span-4"}
                  name="backgroundColor.firstColor"
                  element={ backgroundColor }
                />
                  <ColorPickerWrapper
                    color={ backgroundColor.secondColor }
                    onChange={ (color) => updateBackgroundColor(color, 'secondColor') }
                    label="Second Color"
                    className={'col-span-5 lg:col-span-3 md:col-span-4 sm:col-span-3 xs:col-span-4' + (!backgroundColor.useGradient ? " disabled" : "")}
                    name="backgroundColor.secondColor"
                    element={ backgroundColor }
                  />
                  <Checkbox 
                  element={backgroundColor}
                  setFunction={(event) => updateBackgroundColor(event.target.checked, 'useGradient')}
                  title="Gradient"
                  name="backgroundColor.useGradient"
                  value={backgroundColor.useGradient}
                  className="col-span-3 sm:col-span-2"
                /> 
              </div>
            </div>
          </div>
    );
  }
  
  export default General;