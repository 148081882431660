import React from 'react';
import Range from './range';
import ColorPickerWrapper from './color-picker-wrapper';
import Select from 'react-select';
import {fonts} from '../../constants/fonts';
import Checkbox from './checkbox';
import {debounceQueryUpdate} from '../../hooks/useQueryParams';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontFamily: state.data.family,
    fontSize: '20px'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: state.data.family,
    fontSize: '16px'
  })
}

function Font(props) {
  const {update, settings} = props;
  const {setFontSize, setFontColor, setSelectedFont, setTitleVisible, setStackText} = update;
  const {fontSize, fontColor, selectedFont, titleVisible, stackText} = settings;


  const onSelect = (font, name, selectedFont) => {
    setSelectedFont(font);
    debounceQueryUpdate(font, name, selectedFont);
  }

    return (
      <div className="flex-auto p-10 pb-5 width-50% text-stone-500 col-span-2 md:col-span-1">
        <p className="section-title">Text</p>
        <div className ="grid grid-cols-6 gap-12 py-5">
            <div className="col-span-6 sm:col-span-3">
              <p className="control-title">Font</p>
              <Select value={selectedFont} styles={customStyles} options={fonts} onChange={(font) => onSelect(font, "selectedFont", selectedFont)} />
            </div>
            
            <Range name="fontSize" label="Size" setFunction={setFontSize} value={fontSize} max={50} min={10} className="col-span-4 sm:col-span-3"/>
            
          
          <ColorPickerWrapper
              color={ fontColor }
                    onChange={ setFontColor }
                    label="Color"
                    className={"col-span-2"}
                    name="fontColor"
            />
          <Checkbox 
          name="titleVisible"
          value={titleVisible}
          setFunction={(event) => setTitleVisible(event.target.checked)}
          className={"col-span-1 " + (stackText ? "disabled" : "")}
          title="Title"
          />

          <Checkbox 
          name="stackText"
          setFunction={(event) => setStackText(event.target.checked)}
          className={"col-span-1 " + (!titleVisible ? "disabled" : "")}
          value={stackText}
          title="Stack"
          />
        </div>
      </div>
    );
  }
  
  export default Font;