import React from 'react';
import '../../../twitch-goal.css';

const video = process.env.PUBLIC_URL + '/Videos/background2.webm';

function TwitchGoal(props) {
  const {settings} = props;
  const {borderRadius, borderColor, fontSize, imgVisible, fontColor, width, height, borderVisible, borderSize, progressPosition, progressBarHeight, selectedFont, titleVisible, stackText} = settings;

  const deriveColor = (element) => {
    if(settings[element].useGradient){
      return 'linear-gradient(90deg, ' + settings[element].firstColor + ' 0%, ' + settings[element].secondColor + ' 100%)';
    }
    return settings[element].firstColor;
  }


  return (
    <>
    <div className="lg:pt-32 xl:pt-40 sm:pb-60 md:pb-96 relative z-0 twitch-goal-wrapper" style={{fontFamily: selectedFont?.family}}>
      <video autoPlay muted loop id="myVideo">
        <source src={video} type="video/webm"/>
      </video>
      <div className="container mx-auto flex justify-center ">
      
        <div className="twitch-goal mx-auto">
        
          <div id="root" data-a-page-loaded-name="CreatorGoalOverlayPage" data-a-page-loaded="1650499726738" data-a-page-events-submitted="1650499727887">
            <div className="Layout-sc-nxg1ff-0 byGGdm goal_widget__container  mx-auto " style={{fontSize: fontSize + "px", width: width + "px", height: height + "px"}}>
              <div className="Layout-sc-nxg1ff-0 iqukYR goal_widget" style={{background: deriveColor('backgroundColor'), border: borderVisible ? borderSize + 'px solid ' + borderColor : '0px solid ' + borderColor, borderRadius: ((borderRadius * height) / 100) + "px", borderColor: borderColor}}>
                <div className="Layout-sc-nxg1ff-0 gCfFSD goal_widget__progress_bar" style={{display:(progressPosition === 'off' ? 'none' : 'initial'), width: '66.6667%', background: deriveColor('progressColor'), top: progressPosition === 'bottom' ? 'initial' : '0px', bottom: progressPosition === 'top' ? 'initial' : '0px', height: progressPosition === 'top' || progressPosition === 'bottom' ? progressBarHeight + 'px' : '100%'}}></div>
                <div className="Layout-sc-nxg1ff-0 efUjoc goal_widget__body" style={{paddingLeft: (imgVisible ? '2rem' : '0rem')}}>
                  {imgVisible && <img className="goal_widget__image tw-image" alt="Sub Goal" src="https://static.twitchcdn.net/assets/subscribe-2d3225207e704bd2aa2d.svg" />}
                  <div className="Layout-sc-nxg1ff-0 gpXMOj goal_widget__metadata" style={{flexDirection: (stackText ? 'column' : 'row'), justifyContent: (stackText ? 'center' : 'space-between'), alignItems: (stackText ? 'flex-start' : 'center')}}>
                    <div className="Layout-sc-nxg1ff-0 gcwIMz" style={{color: fontColor, display: (!titleVisible ? 'none' : 'flex')}}>Sub Goal</div>
                    <div className="Layout-sc-nxg1ff-0 hiuuyA goal_widget__contributions" style={{color: fontColor}}>
                      <span className="CoreText-sc-cpl358-0 NPvqY InjectLayout-sc-588ddc-0 ScTransitionBase-sc-eg1bd7-0 jNvUhD neUJc tw-transition">20</span> / 30 Total Subs
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </>
  );
}

export default TwitchGoal;