import React, {useState, useEffect, useRef} from 'react';
import { ChromePicker } from 'react-color';
import {debounceQueryUpdate} from '../../hooks/useQueryParams';

const transparencyCheckerboard = process.env.PUBLIC_URL + '/img/transparency-checkerboard.jpg';

function useOutsideAlerter(ref) {
    const [outsideElement, setOutsideElement] = useState(true);
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOutsideElement(true);
        } else {
            setOutsideElement(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mouseup", handleClickOutside);
      };
    }, [ref]);
    return [outsideElement];
  }

  const determineHex = (color, a) => {
    let colorParam;
    let alpha = Math.floor((a * 255)).toString(16);
    if(color === 'transparent'){
      colorParam = '000000'
    } else {
      colorParam = color;
    }
    if(alpha.length === 1){
      alpha = '0' + alpha;
    }
    return colorParam + alpha;
  }
  

function ColorPickerWrapper(props) {
  const {color, onChange, label, name, element} = props;
  const [showColorPicker, setShowColorPicker] = useState(false);

  const wrapperRef = useRef(null);
  const [outsideClick] = useOutsideAlerter(wrapperRef);
  
  useEffect(()=> {
      if(outsideClick){
        setShowColorPicker(false);
      }
  }, [outsideClick]);

  const onChangeEvent = (color) => {
    const {r, b, g, a} = color.rgb;
    const colorParam = determineHex(color.hex, a);
    
    onChange("rgba(" + r + ", " + g + ", " + b + ", " + a + ")");
    debounceQueryUpdate(colorParam, name, element);
  }

    return (
        <div className={"color-picker "  + (props.className ? props.className : '')} >
            <div>
                <span className="control-title">{label}</span>
                <div className="color-picker-button" onClick={() => showColorPicker ? setShowColorPicker(false) : setShowColorPicker(true)}>
                    <span style={{background: (`linear-gradient(to right, ${color}, ${color}), url(${transparencyCheckerboard})`)}} />
                </div>
            </div>
            {showColorPicker &&
                <div className="color-picker-selector" ref={wrapperRef}>
                    <ChromePicker 
                        color={ color }
                        onChange={ onChangeEvent }
                        />
                </div>
            }
        </div>
    );
  }
  
  export default ColorPickerWrapper;